<script setup lang="ts">
import type { HeroBannerItem } from '@laam/cms-shared';
import { useWindowScroll, watchDebounced, watchOnce } from '@vueuse/core';
import LazyImage from '~/components/LazyImage.vue';
import { vElementVisibility } from '@vueuse/components';

interface Props {
	data: HeroBannerItem;
	sectionIndex: number;
}

const { data, sectionIndex } = defineProps<Props>();

const { $cathodeClient, $eventClient } = useNuxtApp();

const { deviceType } = useDeviceType();

const imageUrl = computed(() => {
	return deviceType.value === 'desktop' ? data.desktop_url : data.mobile_url;
});

const entity = ref('');

const isVisible = ref(false);

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchOnce(y, () => {
	hasWindowScrolled.value = true;
});

function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						identifier: data.link.href.split('/')[2],
						data_string: JSON.stringify(data),
					},
				],
				vertical_index: sectionIndex,
				entity: entity.value as 'node' | 'brand' | 'drop',
				heading: '',
				component: 'mini-banner',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: 'manual',
				clicked_from: 'homepage',
				page: 'homepage',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);

const collectionToNode = (url: string) => {
	if (url.includes('collections')) {
		entity.value = 'node';
		return '/nodes' + url.split('collections')[1];
	} else {
		if (url.includes('brands')) {
			entity.value = 'brand';
		} else if (url.includes('drops')) {
			entity.value = 'drop';
		} else if (url.includes('nodes')) {
			entity.value = 'node';
		}
		return url;
	}
};

function handleClickEvent() {
	// Cathode event
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		identifier: data.link.href.split('/')[2]!,
		clicked_from: 'homepage',
		entity: entity.value as 'node' | 'brand' | 'drop',
		horizontal_index: 0,
		vertical_index: sectionIndex,
		slotted: false,
		heading: '',
		component: 'mini-banner',
		product_count: null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: 'manual',
		data_string: JSON.stringify(data),
	});
	// statsig event
	$eventClient.sendEvent('homepage-carousel-click', {
		to: data.link.href,
		index: sectionIndex,
		title: 'mini-banner',
	});
}
</script>
<template>
	<NuxtLink
		v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
		:to="collectionToNode(data.link.href)"
		as="button"
		:disabled="data.link.href === 'https://laam.pk'"
		class="block w-full lg:rounded-3xl"
		@click="handleClickEvent()"
	>
		<LazyImage
			class="homepage__image-slider-image lg:rounded-3xl"
			:src="imageUrl"
			:alt="imageUrl"
			:width="deviceType === 'mobile' ? 250 : 1032"
			loading="eager"
		/>
	</NuxtLink>
</template>
